@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");

body {
  height: 100%;
  margin: 0;
  padding: 0;
  color: #343a40;
  background-color: #f8f9fe;
  font-family: "Raleway", sans-serif;
}

a {
  color: #fe7c18;
}

a:hover {
  color: #6c757d;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: #2d3339;
  font-family: "Raleway", sans-serif;
}

.btn-circle {
  border-radius: 50px;
}

.shadow {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, .15) !important;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border: 1px solid rgba(0, 0, 0, .05);
  border-radius: 0.375rem;
  background-color: #fff;
  background-clip: border-box;
}

.card-header {
  margin-bottom: 0;
  padding: 1.25rem 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, .05);
  background-color: #fff;
}

.card-body {
  padding: 1.5rem;
  flex: 1 1 auto;
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md {
    position: fixed;
    top: 0;
    bottom: 0;
    display: block;
    overflow-y: auto;
    width: 100%;
    max-width: 250px;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

.navbar-vertical .navbar-expand-md+.main-content {
  margin-left: 250px;
}

.navbar-vertical+.main-content {
  margin-right: 250px;
}

@media (min-width: 768px) {
  .main-content {
    margin-left: 280px;
    margin-right: 30px;
  }
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 7px solid #0d38a1;
  border-top-color: #d1ccff;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ngx-pagination .current {
  background: #0d38a1 !important;
}

/*--------------------------------------------------------------
# PreloaderUsers
--------------------------------------------------------------*/
/* Estilos para el preloader */
.preloaderLogo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.preloader-content {
  text-align: center;
}

.preloader-logo {
  width: auto;
  height: 60px;
}

.preloader-text {
  margin-top: 30px;
  font-size: 20px;
  color: #031E3A;
  font-weight: bold;
}

.dots {
  display: inline-block;
  margin-left: 5px;
  height: 10px;
}

.dots::after {
  content: '..';
  animation: dots 1.5s steps(1, end) infinite;
}

@keyframes dots {

  0%,
  20% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  60% {
    opacity: 0;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


.loadingspinner {
  --square: 26px;
  --offset: 30px;
  --duration: 2.4s;
  --delay: 0.2s;
  --timing-function: ease-in-out;
  --in-duration: 0.4s;
  --in-delay: 0.1s;
  --in-timing-function: ease-out;
  width: calc( 3 * var(--offset) + var(--square));
  height: calc( 2 * var(--offset) + var(--square));
  padding: 0px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 30px;
  position: relative;
  justify-content: center;
}

.loadingspinner div {
  display: inline-block;
  background: #031e3a;
  border: none;
  border-radius: 2px;
  width: var(--square);
  height: var(--square);
  position: absolute;
  padding: 0px;
  margin: 0px;
  font-size: 6pt;
  color: black;
}

.loadingspinner #square1 {
  left: calc( 0 * var(--offset) );
  top: calc( 0 * var(--offset) );
  animation: square1 var(--duration) var(--delay) var(--timing-function) infinite,
               squarefadein var(--in-duration) calc(1 * var(--in-delay)) var(--in-timing-function) both;
}

.loadingspinner #square2 {
  left: calc( 0 * var(--offset) );
  top: calc( 1 * var(--offset) );
  animation: square2 var(--duration) var(--delay) var(--timing-function) infinite,
              squarefadein var(--in-duration) calc(1 * var(--in-delay)) var(--in-timing-function) both;
}

.loadingspinner #square3 {
  left: calc( 1 * var(--offset) );
  top: calc( 1 * var(--offset) );
  animation: square3 var(--duration) var(--delay) var(--timing-function) infinite,
               squarefadein var(--in-duration) calc(2 * var(--in-delay)) var(--in-timing-function) both;
}

.loadingspinner #square4 {
  left: calc( 2 * var(--offset) );
  top: calc( 1 * var(--offset) );
  animation: square4 var(--duration) var(--delay) var(--timing-function) infinite,
               squarefadein var(--in-duration) calc(3 * var(--in-delay)) var(--in-timing-function) both;
}

.loadingspinner #square5 {
  left: calc( 3 * var(--offset) );
  top: calc( 1 * var(--offset) );
  animation: square5 var(--duration) var(--delay) var(--timing-function) infinite,
               squarefadein var(--in-duration) calc(4 * var(--in-delay)) var(--in-timing-function) both;
}

@keyframes square1 {
  0% {
    left: calc( 0 * var(--offset) );
    top: calc( 0 * var(--offset) );
  }

  8.333% {
    left: calc( 0 * var(--offset) );
    top: calc( 1 * var(--offset) );
  }

  100% {
    left: calc( 0 * var(--offset) );
    top: calc( 1 * var(--offset) );
  }
}

@keyframes square2 {
  0% {
    left: calc( 0 * var(--offset) );
    top: calc( 1 * var(--offset) );
  }

  8.333% {
    left: calc( 0 * var(--offset) );
    top: calc( 2 * var(--offset) );
  }

  16.67% {
    left: calc( 1 * var(--offset) );
    top: calc( 2 * var(--offset) );
  }

  25.00% {
    left: calc( 1 * var(--offset) );
    top: calc( 1 * var(--offset) );
  }

  83.33% {
    left: calc( 1 * var(--offset) );
    top: calc( 1 * var(--offset) );
  }

  91.67% {
    left: calc( 1 * var(--offset) );
    top: calc( 0 * var(--offset) );
  }

  100% {
    left: calc( 0 * var(--offset) );
    top: calc( 0 * var(--offset) );
  }
}

@keyframes square3 {
  0%,100% {
    left: calc( 1 * var(--offset) );
    top: calc( 1 * var(--offset) );
  }

  16.67% {
    left: calc( 1 * var(--offset) );
    top: calc( 1 * var(--offset) );
  }

  25.00% {
    left: calc( 1 * var(--offset) );
    top: calc( 0 * var(--offset) );
  }

  33.33% {
    left: calc( 2 * var(--offset) );
    top: calc( 0 * var(--offset) );
  }

  41.67% {
    left: calc( 2 * var(--offset) );
    top: calc( 1 * var(--offset) );
  }

  66.67% {
    left: calc( 2 * var(--offset) );
    top: calc( 1 * var(--offset) );
  }

  75.00% {
    left: calc( 2 * var(--offset) );
    top: calc( 2 * var(--offset) );
  }

  83.33% {
    left: calc( 1 * var(--offset) );
    top: calc( 2 * var(--offset) );
  }

  91.67% {
    left: calc( 1 * var(--offset) );
    top: calc( 1 * var(--offset) );
  }
}

@keyframes square4 {
  0% {
    left: calc( 2 * var(--offset) );
    top: calc( 1 * var(--offset) );
  }

  33.33% {
    left: calc( 2 * var(--offset) );
    top: calc( 1 * var(--offset) );
  }

  41.67% {
    left: calc( 2 * var(--offset) );
    top: calc( 2 * var(--offset) );
  }

  50.00% {
    left: calc( 3 * var(--offset) );
    top: calc( 2 * var(--offset) );
  }

  58.33% {
    left: calc( 3 * var(--offset) );
    top: calc( 1 * var(--offset) );
  }

  100% {
    left: calc( 3 * var(--offset) );
    top: calc( 1 * var(--offset) );
  }
}

@keyframes square5 {
  0% {
    left: calc( 3 * var(--offset) );
    top: calc( 1 * var(--offset) );
  }

  50.00% {
    left: calc( 3 * var(--offset) );
    top: calc( 1 * var(--offset) );
  }

  58.33% {
    left: calc( 3 * var(--offset) );
    top: calc( 0 * var(--offset) );
  }

  66.67% {
    left: calc( 2 * var(--offset) );
    top: calc( 0 * var(--offset) );
  }

  75.00% {
    left: calc( 2 * var(--offset) );
    top: calc( 1 * var(--offset) );
  }

  100% {
    left: calc( 2 * var(--offset) );
    top: calc( 1 * var(--offset) );
  }
}

@keyframes squarefadein {
  0% {
    transform: scale(0.75);
    opacity: 0.0;
  }

  100% {
    transform: scale(1.0);
    opacity: 1.0;
  }
}

/*--------------------------------------------------------------
# Button Styles
--------------------------------------------------------------*/

.btn-outline-custom {
  border: 0px;
  width: 100%;
}

.dropdown-toggle::after {
  color: transparent !important;
}

.btn-outline-custom:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline-width: 0;
}

.btn-red {
  background-color: #0d38a1;
  color: #fff;
  font-weight: 600;
  border-radius: 3px;
  min-width: 150px;
  padding: 0.7rem 2rem;

  &:hover {
    background-color: #000;
    cursor: pointer;
    color: #fff;
  }
}

.btn-gray {
  background-color: #ffffff;
  color: #000;
  font-weight: 600;
  border-radius: 3px;
  min-width: 150px;
  padding: 0.7rem 2rem;
  border: #000 1px solid;

  &:hover {
    background-color: #000;
    color: #fff;
    cursor: pointer;
  }
}

.btn-red-cancel {
  background-color: #c40000;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  border-radius: 12px;
  min-width: 150px;
  box-shadow: 10px 10px 20px -5px rgba(0, 0, 0);

  &:hover {
    background-color: #000;
    color: #fff;
    cursor: pointer;
  }
}

.btn-succes {
  background-color: #27a21b;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  border-radius: 12px;
  min-width: 150px;
  box-shadow: 10px 10px 20px -5px rgba(0, 0, 0);

  &:hover {
    background-color: #000;
    cursor: pointer;
    color: #fff;
  }
}

/*--------------------------------------------------------------
# Button checkBox
--------------------------------------------------------------*/

.checkBox {
  label {
    font-weight: 600;
    color: #777777;
    margin-bottom: 11px;
    width: 100%;
    float: left;
    cursor: pointer;
    padding: 0 0.6em;
    box-sizing: border-box;
    background: #e6e6e6;
    transition: all 0.5s ease 0s;
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"]+label {
    line-height: 2.35em;
  }

  input[type="checkbox"]:disabled+label {
    color: #ccc !important;
    cursor: not-allowed;
  }

  input[type="checkbox"]:checked:disabled+label:after {
    border-color: #ccc;
  }

  input[type="checkbox"]+label:before {
    content: "";
    width: 26px;
    height: 26px;
    float: left;
    margin-right: 0.5em;
    border: 2px solid #ccc;
    background: #fff;
    margin-top: 0.35em;
  }

  input[type="checkbox"]:checked+label {
    background: #c1eec2;
  }

  input[type="checkbox"]:checked+label:after {
    content: "";
    width: 12px;
    height: 6px;
    border: 4px solid #0fbf12;
    float: left;
    margin-left: -1.95em;
    border-right: 0;
    border-top: 0;
    margin-top: 1em;
    transform: rotate(-55deg);
  }

  input[type="checkbox"]:checked+label:before {
    border-color: #0fbf12;
  }

  input[type="checkbox"]:checked:disabled+label {
    background: #ccc;
    color: #fff !important;
  }

  input[type="checkbox"]:checked:disabled+label:before {
    border-color: #bdbdbd;
  }

  @media (max-width: 650px) {
    .content {
      width: 100%;
    }
  }

}

/*--------------------------------------------------------------
# Button Add
--------------------------------------------------------------*/

.btn-inverse-red {
  color: #cc0022;
  background-color: rgba(204, 0, 34, .2);
  border-color: rgba(63, 80, 246, 0);

  &:hover {
    color: #fff;
    background-color: #cc0022;
    border-color: #cc0022;
  }
}

/*--------------------------------------------------------------
# Add Map
--------------------------------------------------------------*/

.card-icon {
  border-style: dashed;
  border-width: 2.4px;
}

.img-icon {
  width: 80px;
  margin: 0 auto;
  display: block;
}

div.profile-card {
  padding: 12px;
  background-color: #d9e9f275;
  color: #2b2b2b;
}

.upload {
  opacity: 0;
  height: 30px;
}

.ctn-file {
  height: 40px;
}

#upload-label-img {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
}

/*--------------------------------------------------------------
# Button Add And Table
--------------------------------------------------------------*/

.table thead th {
  border-top: 0;
  border-bottom-width: 1px;
  font-weight: 500;
}

.btn-red-sm {
  background-color: #cc0022;
  color: #fff;

  &:hover {
    color: #fff;
    background-color: #b3001e;
    border-color: #b3001e;
  }
}

/*--------------------------------------------------------------
# input spin number
--------------------------------------------------------------*/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}